import React from "react";
import ButtonRow from "../../components/buttonRow";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/logo";

function OnboardingWrapper({
  children,
  title,
  introduction,
  buttonAction,
  buttonText,
  errorMessage,
  align,
  hideBack = false,
  wide = false,
}) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const handleFormKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      buttonAction(event);
    }
  };
  return (
    <>
      <header data-align={align} className='onboarding-header'>
        <Logo type='icon' />
        <h1>{title}</h1>
        <p className='introduction'>{introduction}</p>
      </header>
      <form onSubmit={buttonAction} onKeyDown={handleFormKeyDown}>
        {children}
      </form>
      <footer className='onboarding-footer'>
        <ButtonRow justify={hideBack ? "end" : "space-between"}>
          <div className='submit-button'>
            {errorMessage && <div className='error-message'>{errorMessage}</div>}
            <Button tabndex={2} label={buttonText} onClick={buttonAction} />
          </div>

          {hideBack ? "" : <Button tabIndex={3} type='text' label='&laquo; Back' onClick={goBack} />}
        </ButtonRow>
      </footer>
    </>
  );
}

export default OnboardingWrapper;
