export const stepsData = ({ companyName, firstName, lastName }) => {
  return [
    {
      id: 1,
      to: "/",
      title: "Your details",
      autoFocus: true,
      subtitle: "Tell us about yourself",
    },
    {
      id: 2,
      to: "/role",
      title: "Your role",
      subtitle: "Tell us about your role",
    },
    {
      id: 3,
      to: "/company",
      title: "Your company",
      subtitle: "Customize your experience",
    },
    {
      id: 4,
      to: "/tools",
      title: "Tools",
      subtitle: `What tools do you use?`,
    },
    {
      id: 5,
      to: "/problems",
      title: "Problems",
      subtitle: "What do you want to solve?",
    },
    {
      id: 6,
      to: "/get-started",
      title: "Completed",
      subtitle: "You're ready to get started!",
    },
  ];
};
