import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/user.context";
import OnboardingWrapper from "../../layouts/onboarding/layout.onboarding.wrapper";
import { navigateUser } from "../../utilities/onboarding.navigateUser";
import { companyTypeData as companyTypes } from "../../data/data.companyTypes";
import RadioCard from "../../components/radioCard";

function OnboardingCompany() {
  const { user, setUser } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    companyType: user.companyType || "",
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    setUser({
      ...user,
      companyType: formData.companyType,
    });

    // Check if all fields are filled
    const { companyType } = formData; // Update: Check formData instead of user
    if (companyType) {
      navigate("/tools");
    } else {
      // Set the error message
      setErrorMessage("Please select an option"); // Update: Adjust the error message
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    navigateUser(user);
  }, [user]);

  return (
    <OnboardingWrapper
      title={`Which of the following best describes ${
        user.companyName ? user.companyName : "your company"
      }?`}
      introduction='Different sized companies work in different ways.'
      buttonAction={handleSubmit}
      buttonText='Next &raquo;'
      wide='true'
      errorMessage={errorMessage}
    >
      <div className='onboarding-step onboarding-step-about'>
        <label className='radio-card-container'>
          <div className='checkbox-card-container' data-grid-columns='3'>
            {companyTypes.map((companyType) => (
              <RadioCard
                key={companyType.id}
                name='companyType'
                value={companyType.name}
                checked={formData.companyType === companyType.name}
                label={companyType.name}
                icon={companyType.icon}
                onClick={() => setFormData({ ...formData, companyType: companyType.name })}
                onChange={handleInputChange}
                description={companyType.description}
              />
            ))}
          </div>
        </label>
      </div>
    </OnboardingWrapper>
  );
}

export default OnboardingCompany;
