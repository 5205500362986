import { useContext } from "react";
import { stepsData } from "../../data/data.steps";
import Logo from "../../components/logo";
import LayoutOnboardingData from "./layout.onboarding.data";
import { UserContext } from "../../contexts/user.context";
import OnboardingSteps from "./onboarding.steps";
import LayoutOnboardingCopyright from "./layout.onboarding.copyright";

function LayoutProgress() {
  const { user } = useContext(UserContext);

  return (
    <div className='onboarding-progress'>
      <header>
        <Logo />
      </header>
      <OnboardingSteps steps={stepsData(user)} />
      <LayoutOnboardingData />
      <LayoutOnboardingCopyright />
    </div>
  );
}

export default LayoutProgress;
