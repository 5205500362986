import React from "react";
import Icon from "../icon";

const RadioCard = ({
  id,
  name,
  value,
  label,
  description,
  image,
  icon,
  checked,
  align,
  onChange,
  onClick,
  tabIndex,
  autoFocus,
}) => {
  return (
    <div className='radio-card' onClick={onClick} data-checked={checked} data-align={align}>
      <input
        id={id}
        type='radio'
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        tabIndex={0}
        autoFocus={autoFocus}
      />
      <label htmlFor={id}>
        {image ? <img src={image} alt={name} /> : null}
        {icon ? <Icon name={icon} /> : null}
        {label} <p className='description'>{description}</p>
      </label>
    </div>
  );
};

export default RadioCard;
