import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/user.context";
import OnboardingWrapper from "../../layouts/onboarding/layout.onboarding.wrapper";
import { navigateUser } from "../../utilities/onboarding.navigateUser";
import { roleData as jobs } from "../../data/data.roles";
import RadioCard from "../../components/radioCard";

function OnboardingRole() {
  const { user, setUser } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    jobTitle: user.jobTitle || "",
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    setUser({
      ...user,
      jobTitle: formData.jobTitle,
    });

    // Check if all fields are filled
    const { jobTitle } = formData; // Update: Check formData instead of user
    if (jobTitle) {
      navigate("/company");
    } else {
      // Set the error message
      setErrorMessage("Please select an option"); // Update: Adjust the error message
    }
  };

  useEffect(() => {
    navigateUser(user);
  }, [user]);

  return (
    <OnboardingWrapper
      title='What kind of work do you do?'
      introduction='This helps us tune your AI to your company needs.'
      buttonAction={handleSubmit}
      buttonText='Next &raquo;'
      wide='true'
      errorMessage={errorMessage}
    >
      <div className='onboarding-step onboarding-step-about'>
        <label className='radio-card-container'>
          <div className='checkbox-card-container' data-grid-columns='4'>
            {jobs.map((job) => (
              <RadioCard
                key={job.id}
                name='jobTitle'
                value={job.name}
                checked={formData.jobTitle === job.name}
                label={job.name}
                icon={job.icon}
                autoFocus={job.autoFocus}
                tabIndex={job.id}
                onClick={() => setFormData({ ...formData, jobTitle: job.name })}
                onChange={(event) =>
                  setUser({
                    ...user,
                    jobTitle: event.target.value,
                  })
                }
              />
            ))}
          </div>
        </label>
      </div>
    </OnboardingWrapper>
  );
}

export default OnboardingRole;
