import IconContainer from "../icon.container";

function IconCheckCircle(props) {
  return (
    <IconContainer size={props.size} classes={props.classes}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 10L11 15L8 12'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </IconContainer>
  );
}

export default IconCheckCircle;
