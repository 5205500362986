import IconContainer from "../icon.container";

function IconExecutive(props) {
  return (
    <IconContainer size={props.size} classes={props.classes}>
      <path
        d='M13.238 14.7994C13.7387 15.3001 13.8885 16.0531 13.6175 16.7073C13.3465 17.3615 12.7081 17.7881 12 17.7881C11.2919 17.7881 10.6535 17.3615 10.3826 16.7073C10.1116 16.0531 10.2614 15.3001 10.7621 14.7994C11.4458 14.1157 12.5543 14.1157 13.238 14.7994'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.7398 10.2976C18.4235 10.9813 18.4235 12.0898 17.7398 12.7735C17.0561 13.4572 15.9476 13.4572 15.2639 12.7735C14.5802 12.0898 14.5802 10.9813 15.2639 10.2976C15.5922 9.96924 16.0376 9.78479 16.5019 9.78479C16.9662 9.78479 17.4115 9.96924 17.7398 10.2976'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.0037 13.0004V5.24715C21.0037 4.00399 19.996 2.99622 18.7528 2.99622H5.24719C4.00403 2.99622 2.99625 4.00399 2.99625 5.24715V13.0004'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.0021 16.0017C18.0854 15.9952 19.1405 16.3469 20.0033 17.0021'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.24885 11.7499C9.24885 12.7168 8.46503 13.5006 7.49812 13.5006C6.53122 13.5006 5.7474 12.7168 5.7474 11.7499C5.7474 10.783 6.53122 9.99915 7.49812 9.99915C8.46503 9.99915 9.24885 10.783 9.24885 11.7499'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.99667 17.0021C4.84494 16.3582 5.88221 16.0125 6.94721 16.0189'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.0012 21.0037C13.2227 19.6698 10.7773 19.6698 8.99875 21.0037'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </IconContainer>
  );
}

export default IconExecutive;
