import { useContext } from "react";
import { UserContext } from "../../contexts/user.context";
import { roleData } from "../../data/data.roles";
import { problemsData } from "../../data/data.problems";
import Logo from "../../components/logo";
import LayoutOnboardingCopyright from "../../layouts/onboarding/layout.onboarding.copyright";
import LayoutOnboardingData from "../../layouts/onboarding/layout.onboarding.data";
import { Link } from "react-router-dom";

function getRoleDescriber(role) {
  const roleInfo = roleData.find((r) => r.id === role || r.name === role);
  return roleInfo ? roleInfo.describer : "";
}

function getProblemDescriber(problems) {
  const selectedProblems = problemsData.filter(
    (p) => problems.includes(p.id) || problems.includes(p.name)
  );
  const describers = selectedProblems.map((p) => p.describer).slice(0, 2);
  return describers.join(" and ");
}

function FinaleMessage() {
  const { user } = useContext(UserContext);
  const { firstName, jobTitle, problems, tools } = user;
  const canLogIn = tools.includes("Slack") && tools.includes("GitHub");

  // Get the describer for up to two of the selected problems
  const problemDescribers = problems
    .map((p) => getProblemDescriber(p))
    .filter((d) => d !== "")
    .slice(0, 2);

  return (
    <div className='onboarding-finale-message'>
      <Logo type='icon' />
      <h1>Welcome to Blueprint AI.</h1>
      <div className='onboarding-finale-message-content'>
        <p>We're excited to have you here, {firstName}!</p>
        <p>
          At Blueprint, we work with {getRoleDescriber(jobTitle) || "teams like yours"} to help you{" "}
          {problemDescribers.length > 0 ? problemDescribers.join(", and ") : "problems"} by giving
          you access to the information you need, when you need it. All without having to ask a
          colleague, or root through{" "}
          {tools
            .sort(() => 0.5 - Math.random())
            .slice(0, 2)
            .join(" or ") || "your tools"}
          .
        </p>
      </div>
      <div className='result'>
        {canLogIn ? (
          <Link
            className='button onboarding-finale-button'
            to='https://app.blueprint.ai/a/signup'
            target='_blank'
            rel='noopener noreferrer'
          >
            Create your account
          </Link>
        ) : (
          <>
            <h4>You've joined the waitlist</h4>
            <p>
              We're currently operating a waitlist to help people get started with Blueprint. We'll
              be in touch shortly. If you need anything in the meantime, let us know at{" "}
              <a href='mailto:support@blueprint.ai'>support@blueprint.ai</a>.
            </p>
          </>
        )}
      </div>

      <LayoutOnboardingData />
      <LayoutOnboardingCopyright />
    </div>
  );
}

export default FinaleMessage;
