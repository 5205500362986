export const roleData = [
  {
    id: 1,
    name: "Product",
    icon: "product",
    describer: "Product manager and designers",
    autoFocus: true,
  },
  {
    id: 2,
    name: "Engineering",
    describer: "engineers, and engineering leadership",
    icon: "engineering",
  },
  {
    id: 3,
    name: "Support",
    describer: "support teams, and leaders",
    icon: "support",
  },
  {
    id: 4,
    name: "Executive",
    icon: "executive",
    describer: "leaders",
  },
];
