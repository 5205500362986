import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "./../../contexts/user.context";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button";

function LayoutOnboardingData() {
  const navigate = useNavigate();

  const { user, clearUser } = useContext(UserContext);

  const handleClearUser = () => {
    clearUser();
    navigate("/");
  };

  const [showData, setShowData] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "g") {
        setShowData(!showData);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [showData]);

  return (
    <>
      {showData && (
        <div className='data-container'>
          <div className='data-output'>
            <h5>Output</h5>
            <ul>
              <li>
                <strong>First Name:</strong> {user.firstName}
              </li>
              <li>
                <strong>Last Name:</strong> {user.lastName}
              </li>
              <li>
                <strong>Company Name:</strong> {user.companyName}
              </li>
              <li>
                <strong>Email:</strong> {user.email}
              </li>
              <li>
                <strong>Job Title:</strong> {user.jobTitle}
              </li>
              <li>
                <strong>Company Type:</strong> {user.companyType}
              </li>
              <li>
                <strong>Problems:</strong> {user.problems && user.problems.join(", ")}
              </li>
              <li>
                <strong>Tools:</strong> {user.tools && user.tools.join(", ")}
              </li>
              <li>
                <strong>Suggested Tools:</strong> {user.toolSuggestion}
              </li>
              <Button onClick={handleClearUser} label='Clear User Data' />
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default LayoutOnboardingData;
