export const createLead = async (user) => {
  const apiUrl = "https://api.blueprint.ai/rest/v1/onboarding";

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });

    if (!response.ok) {
      throw new Error("Failed to post data to API.");
    }
    console.log("Data posted successfully!");
  } catch (error) {
    console.error("Error:", error);
  }
};

// Post body example:
// {
//     "firstName": "John",
//     "lastName": "Doe",
//     "companyName": "Example Company",
//     "email": "john.doe@example.com",
//     "jobTitle": "engineering",
//     "companyType": "Modern Tech Co.",
//     "problems": ["Problem1", "Problem2"],
//     "tools": ["Tool1", "Tool2"],
//     "toolSuggestion": "Tool suggestion"
//   }