import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckBoxCard from "../../components/checkboxCard";
import { UserContext } from "../../contexts/user.context";
import { toolsData } from "../../data/data.tools";
import OnboardingWrapper from "../../layouts/onboarding/layout.onboarding.wrapper";
import { navigateUser } from "../../utilities/onboarding.navigateUser";
import Input from "../../components/input";

function OnboardingTools() {
  const { user, setUser } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const handleCheckboxChange = (event) => {
    const { name } = event.target;
    const { tools } = user;
    const index = tools?.indexOf(name) ?? -1;
    if (index === -1) {
      setUser({
        ...user,
        tools: [...(tools ?? []), name],
      });
    } else {
      setUser({
        ...user,
        tools: [...tools.slice(0, index), ...tools.slice(index + 1)],
      });
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if all fields are filled
    const { tools } = user;
    if (tools) {
      // All fields are filled, navigate to the next page
      navigate("/problems");
    } else {
      // Set the error message
      setErrorMessage("Please fill in all fields");
    }
  };
  useEffect(() => {
    navigateUser(user);
  }, [user]);

  return (
    <OnboardingWrapper
      title={`What tools do you use?`}
      introduction='This will help us set up your integrations.'
      buttonAction={handleSubmit}
      buttonText='Next &raquo;'
      errorMessage={errorMessage}
    >
      <div className='onboarding-step onboarding-step-tools'>
        <div className='checkbox-card-container' data-grid-columns='4'>
          {toolsData.map((tool) => (
            <CheckBoxCard
              align='center'
              key={tool.id}
              name={tool.name}
              autoFocus={tool.autoFocus}
              value={tool.name}
              checked={user.tools?.includes(tool.name)}
              image={`/assets/images/external-logos/${tool.image}`}
              label={tool.name}
              tabIndex={tool.id}
              onClick={() =>
                setUser((prevUser) => ({
                  ...prevUser,
                  tools: prevUser.tools?.includes(tool.name)
                    ? [...prevUser.tools.filter((name) => name !== tool.name)]
                    : [...(prevUser.tools ?? []), tool.name],
                }))
              }
              onChange={handleCheckboxChange}
              description={tool.description}
            />
          ))}
        </div>
        <Input
          label='Other tools'
          value={user.toolSuggestion}
          placeholder='If you use important tools that are not listed above, please list them here.'
          onChange={(event) =>
            setUser({
              ...user,
              toolSuggestion: event.target.value,
            })
          }
          required={true}
        />
      </div>
    </OnboardingWrapper>
  );
}

export default OnboardingTools;
