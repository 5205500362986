import React from "react";
import Icon from "../icon";

const CheckBoxCard = ({
  id,
  name,
  label,
  description,
  image,
  icon,
  checked = false,
  onChange,
  align,
  onClick,
  autoFocus,
}) => {
  return (
    <div
      className='checkbox-card'
      data-align={align}
      data-image={image ? "true" : null}
      onClick={onClick}
      data-checked={checked}
    >
      <input
        id={id}
        type='checkbox'
        name={name}
        checked={checked}
        onChange={onChange}
        tabIndex={0}
        autoFocus={autoFocus}
      />
      <label htmlFor={id}>
        {image ? (
          <div className='image-container'>
            <img src={image} alt={name} />
          </div>
        ) : null}
        {icon ? <Icon name={icon} /> : null}
        <span className='label'>{label}</span>
        {description ? <p className='description'>{description}</p> : null}
      </label>
    </div>
  );
};

export default CheckBoxCard;
