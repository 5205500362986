import IconBigCo from "./icons/bigCo";
import IconEngineering from "./icons/engineering";
import IconExecutive from "./icons/executive";
import IconModernEnterprise from "./icons/modernEnterprise";
import IconProduct from "./icons/product";
import IconStartup from "./icons/startup";
import IconSupport from "./icons/support";
import IconEmail from "./icons/email";
import IconCheckCircle from "./icons/checkCircle";
import IconCircle from "./icons/circle";

const Icon = (props) => {
  switch (props.name) {
    // company types
    case "big-co":
      return <IconBigCo size={props.size} classes={props.classes} />;
    case "modern-enterprise":
      return <IconModernEnterprise size={props.size} classes={props.classes} />;
    case "startup":
      return <IconStartup size={props.size} classes={props.classes} />;
    // roles

    case "engineering":
      return <IconEngineering size={props.size} classes={props.classes} />;
    case "support":
      return <IconSupport size={props.size} classes={props.classes} />;
    case "product":
      return <IconProduct size={props.size} classes={props.classes} />;
    case "executive":
      return <IconExecutive size={props.size} classes={props.classes} />;
    case "email":
      return <IconEmail size={props.size} classes={props.classes} />;
    case "circle":
      return <IconCircle size={props.size} classes={props.classes} />;
    case "check-circle":
      return <IconCheckCircle size={props.size} classes={props.classes} />;
    default:
      <></>;
  }
};

Icon.defaultProps = {
  size: "24",
};
export default Icon;
