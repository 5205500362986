export const problemsData = [
  {
    id: 1,
    name: "Keep up with project status",
    description:
      "Blueprint AI keeps an eye on your projects and sends you status reports.",
    describer: "prevent spending lots of time keeping up to date on projects",
  },
  {
    id: 2,
    name: "Stay in the loop",
    description:
      "Check on the specific features you care about and alert you when they change.",
    describer: "with staying in-the-loop on projects",
  },
  {
    id: 3,
    name: "Coordinate with other teams",
    description:
      "Are you waiting for other teams to complete their work?",
    describer: "with understanding what you need to work on right now",
  },
  {
    id: 4,
    name: "Connect with user feedback",
    description:
      "Our AI can alert you when customers raise important issues.",
  },
];
