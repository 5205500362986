import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CheckBoxCard from "../../components/checkboxCard";
import { UserContext } from "../../contexts/user.context";
import { problemsData } from "../../data/data.problems";
import OnboardingWrapper from "../../layouts/onboarding/layout.onboarding.wrapper";
import { navigateUser } from "../../utilities/onboarding.navigateUser";
import { createLead } from "../../contexts/blueprint.createLead";

function OnboardingProblems() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedProblems, setSelectedProblems] = useState([]);
  const handleCheckboxChange = (event) => {
    const { name } = event.target;
    const { problems } = user;
    const index = problems?.indexOf(name) ?? -1;
    if (index === -1) {
      setUser({
        ...user,
        problems: [...(problems ?? []), name],
      });
    } else {
      setUser({
        ...user,
        problems: [...problems.slice(0, index), ...problems.slice(index + 1)],
      });
    }
    const problem = event.target.value;
    const isSelected = event.target.checked;
    if (isSelected) {
      setSelectedProblems([...selectedProblems, problem]);
    } else {
      setSelectedProblems(selectedProblems.filter((p) => p !== problem));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if all fields are filled
    const { problems } = user;
    if (problems && problems.length > 0) {
      // All fields are filled, navigate to the next page
      navigate("/get-started");
      createLead(user);
    } else {
      // Set the error message
      setErrorMessage("Please select at least one option");
    }
  };
  useEffect(() => {
    navigateUser(user);
  }, [user]);
  return (
    <OnboardingWrapper
      title='How can Blueprint AI help you?'
      introduction='This helps us decide what to work on next.'
      buttonAction={handleSubmit}
      buttonText='Next &raquo;'
      errorMessage={errorMessage}
    >
      <div className='onboarding-step onboarding-step-problems'>
        <div className='checkbox-card-container' data-grid-columns='1'>
          {problemsData.map((problem) => (
            <CheckBoxCard
              align='left'
              key={problem.id}
              name={problem.name}
              value={problem.name}
              autoFocus={problem.autoFocus}
              checked={user.problems?.includes(problem.name)}
              label={problem.name}
              tabIndex={problem.id}
              onClick={() =>
                setUser((prevUser) => ({
                  ...prevUser,
                  problems: prevUser.problems?.includes(problem.name)
                    ? [...prevUser.problems.filter((name) => name !== problem.name)]
                    : [...(prevUser.problems ?? []), problem.name],
                }))
              }
              onChange={handleCheckboxChange}
              description={problem.description}
            />
          ))}
        </div>
      </div>
    </OnboardingWrapper>
  );
}

export default OnboardingProblems;
