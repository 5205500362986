export const companyTypeData = [
  {
    id: 1,
    name: "Startup",
    icon: "startup",
    description: "< 20 engineers",
  },
  {
    id: 2,
    name: "Mid-sized Tech Co.",
    icon: "modern-enterprise",
    description: "< 200 engineers",
  },
  {
    id: 3,
    name: "Established Corp.",
    icon: "big-co",
    description: "200+ engineers",
  },
];
