import FormItem from "../formItem";

function Input({
  type,
  label,
  value,
  onChange,
  id,
  placeholder,
  required,
  instruction,
  autoFocus,
}) {
  return (
    <FormItem>
      <label htmlFor='firstName'>
        {label} {instruction ? <span className='instructions'>{instruction}</span> : ""}
      </label>
      <div className='input-container'>
        <input
          autoFocus={autoFocus}
          type={type}
          value={value}
          onChange={onChange}
          name={id}
          placeholder={placeholder}
          required={required}
        />
      </div>
    </FormItem>
  );
}

Input.defaultProps = {
  type: "text",
  label: "No Label",
  value: "",
  id: "",
  instruction: null,
  required: false,
  placeholder: "",
};

export default Input;
