import IconContainer from "../icon.container";

function IconModernEnterprise(props) {
  return (
    <IconContainer size={props.size} classes={props.classes}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.99749 4.99708H7.99832C8.55084 4.99708 8.99874 5.44498 8.99874 5.9975V7.99833H4.99707V5.9975C4.99707 5.44498 5.44497 4.99708 5.99749 4.99708Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.99792 2.99625V4.99708'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.99625 21.0037V8.99875C2.99625 8.44623 3.44415 7.99833 3.99666 7.99833H9.99916C10.5517 7.99833 10.9996 8.44623 10.9996 8.99875V12'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.0008 12V4.99708C14.0009 4.66906 14.1619 4.36193 14.4315 4.17512C14.7011 3.98831 15.0452 3.94556 15.3524 4.06069L20.3545 5.93647C20.7452 6.08294 21.004 6.45657 21.0037 6.87386V21.0037'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.99832 21.0037V13.0004C7.99832 12.4479 8.44622 12 8.99874 12H15.0012C15.5538 12 16.0017 12.4479 16.0017 13.0004V21.0037'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.4994 17.7024H13.5006'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.4994 15.0012H13.5006'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.0042 21.0037H1.99582'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </IconContainer>
  );
}

export default IconModernEnterprise;
