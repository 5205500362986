import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../components/input";
import FormRow from "../../components/formRow";
import { UserContext } from "../../contexts/user.context";
import { navigateUser } from "../../utilities/onboarding.navigateUser";
import OnboardingWrapper from "../../layouts/onboarding/layout.onboarding.wrapper";
function OnboardingWelcome() {
  const { user, setUser } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    navigateUser(user);
  }, [user]);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if all fields are filled
    const { firstName, lastName, email, companyName } = user;
    if (firstName && lastName && email && companyName) {
      // Check if the email is valid
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(email)) {
        // All fields are filled and the email is valid, navigate to the next page
        navigate("/role");
      } else {
        // Set the error message for an invalid email
        setErrorMessage("Please enter a valid email address");
      }
    } else {
      // Set the error message for empty fields
      setErrorMessage("Please fill in all fields");
    }
  };

  return (
    <>
      <OnboardingWrapper
        title='Welcome to Blueprint AI'
        introduction='Tell us a little about yourself.'
        buttonAction={handleSubmit}
        buttonText='Next &raquo;'
        hideBack='true'
        align='center'
        errorMessage={errorMessage}
      >
        <div data-panel-width='small' className='onboarding-step onboarding-step-welcome'>
          <FormRow>
            <Input
              autoFocus
              label='First name'
              value={user.firstName}
              required={true}
              onChange={(event) =>
                setUser({
                  ...user,
                  firstName: event.target.value,
                })
              }
            />
            <Input
              label='Last name'
              value={user.lastName}
              required={true}
              onChange={(event) =>
                setUser({
                  ...user,
                  lastName: event.target.value,
                })
              }
            />
          </FormRow>
          <FormRow>
            <Input
              label='Email'
              type='email'
              value={user.email}
              required={true}
              placeholder='e.g. you@yourco.com'
              onChange={(event) =>
                setUser({
                  ...user,
                  email: event.target.value,
                })
              }
            />
          </FormRow>
          <FormRow>
            <Input
              label='Company name'
              value={user.companyName}
              required={true}
              onChange={(event) =>
                setUser({
                  ...user,
                  companyName: event.target.value,
                })
              }
            />
          </FormRow>
        </div>
      </OnboardingWrapper>
    </>
  );
}

export default OnboardingWelcome;
