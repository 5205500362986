import IconContainer from "../icon.container";

function IconBigCo(props) {
  return (
    <IconContainer size={props.size} classes={props.classes}>
      <ellipse
        cx='12'
        cy='12'
        rx='4'
        ry='9'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.5 9H3.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='3'
        y='3'
        width='18'
        height='18'
        rx='8'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.5 15H3.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </IconContainer>
  );
}

export default IconBigCo;
