import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { UserProvider } from "./contexts/user.context";
import OnboardingWelcome from "./pages/onboarding/onboarding.welcome";
import OnboardingTools from "./pages/onboarding/onboarding.tools";
import OnboardingProblems from "./pages/onboarding/onboarding.problems";
import OnboardingGetStarted from "./pages/onboarding/onboarding.getStarted";
import LayoutOnboarding from "./layouts/onboarding/layout.onboarding";
import OnboardingRole from "./pages/onboarding/onboarding.role";
import OnboardingCompany from "./pages/onboarding/onboarding.company";
import LayoutOnboardingFinale from "./layouts/onboarding/layout.onboarding.finale";

function App() {
  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route element={<LayoutOnboarding />}>
            <Route path='/' element={<OnboardingWelcome />} />
            <Route path='/role' element={<OnboardingRole />} />
            <Route path='/company' element={<OnboardingCompany />} />
            <Route path='/tools' element={<OnboardingTools />} />
            <Route path='/problems' element={<OnboardingProblems />} />
            <Route path='/*' element={<Navigate to='/' />} />
          </Route>
          <Route element={<LayoutOnboardingFinale />}>
            <Route path='/get-started' element={<OnboardingGetStarted />} />
          </Route>
        </Routes>
      </UserProvider>
    </Router>
  );
}

export default App;
