import React from "react";

function ButtonRow({ children, justify }) {
  return (
    <div className='button-row' data-justify={justify}>
      {children}
    </div>
  );
}

export default ButtonRow;
