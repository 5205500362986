import { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Icon from "../../components/icon";

function OnboardingSteps({ steps }) {
  // eslint-disable-next-line
  const [setActiveStep] = useState(1);
  const location = useLocation();

  const getStepStatus = (stepId) => {
    const path = location.pathname;
    const currentStep = steps.findIndex((step) => step.to === path) + 1;
    if (stepId < currentStep) {
      return "completed";
    } else if (stepId === currentStep) {
      return "active";
    } else {
      return "inactive";
    }
  };

  const filteredSteps = steps.filter((step) => step.to !== "/role");

  return (
    <ul className='progress-items'>
      {filteredSteps.map((step) => (
        <li className={`progress-item ${getStepStatus(step.id)}`} key={step.id}>
          {getStepStatus(step.id) === "completed" ? (
            <Icon name='check-circle' />
          ) : (
            <Icon name='circle' />
          )}
          <div className='progress-item-details'>
            {getStepStatus(step.id) === "completed" ? (
              <h4>
                <Link to={step.to}>{step.title}</Link>
              </h4>
            ) : (
              <h4>{step.title}</h4>
            )}
            <p>{step.subtitle}</p>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default OnboardingSteps;
