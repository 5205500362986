import React from "react";
import { Outlet } from "react-router-dom";

function LayoutOnboardingFinale() {
  return (
    <div className='blueprint-onboarding layout-onboarding-finale'>
      <Outlet />
    </div>
  );
}

export default LayoutOnboardingFinale;
