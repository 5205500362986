import React from "react";

function IconContainer({ size, classes, children }) {
  return (
    <svg
      className={`icon${classes ? ` ${classes}` : ""}`}
      data-size={size}
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {children}
    </svg>
  );
}

export default IconContainer;
