import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../contexts/user.context";
import { navigateUser } from "../../utilities/onboarding.navigateUser";
import { OutputCardData } from "../../data/data.outputCards";
import FinaleMessage from "./onboarding.finaleMessage";


function CountUp({ end }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => prevCount + 1);
    }, 0.05);

    return () => clearInterval(interval);
  }, []);

  return <span>{count <= end ? count : end}</span>;
}

function OnboardingGetStarted() {
  const { user } = useContext(UserContext);
  const { firstName, lastName, companyName, email, jobTitle, companyType, problems, tools } = user;
  const parts = email ? email.split("@") : ["yourco.com"];
  const webAddress = parts[1];
  const [useCases, setUseCases] = useState([]);
  const [randomNumbers, setRandomNumbers] = useState([]);
  const [totalChangesCount, setTotalChangesCount] = useState(0);

  useEffect(() => {
    // Filter the data based on jobTypes, companyTypes, and tools that match the user variables
    const filteredData = OutputCardData(
      firstName,
      lastName,
      webAddress,
      companyName,
      email,
      jobTitle,
      companyType,
      problems,
      tools
    ).filter((useCase) => {
      if (useCase.jobTypes && !useCase.jobTypes.includes(jobTitle)) {
        return false;
      }
      if (useCase.companyTypes && !useCase.companyTypes.includes(companyType)) {
        return false;
      }
      if (useCase.tool && !tools.includes(useCase.tool)) {
        return false;
      }
      return true;
    });

    // Randomly select up to four of the filtered use cases
    const shuffledData = filteredData.sort(() => 0.5 - Math.random());
    const selectedData = shuffledData.slice(0, 6);

    setUseCases(selectedData);
    navigateUser(user);

    // Calculate the sum of changes for tools
    const randomNumbers = selectedData.map(() => Math.floor(Math.random() * 1200)); // Generates a random number between 0 and 799
    const sumOfChanges = randomNumbers.reduce((total, randomNumber) => total + randomNumber, 0);

    // Update the state with the total count and random numbers
    setTotalChangesCount(sumOfChanges); // Add 1402 for Slack
    setRandomNumbers(randomNumbers);
  }, [
    user,
    firstName,
    lastName,
    webAddress,
    companyName,
    email,
    jobTitle,
    companyType,
    problems,
    tools,
  ]);

  const newDate = new Date();
  const formattedDate = newDate.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
  });

  const suffix =
    newDate.getDate() % 10 === 1 && newDate.getDate() !== 11
      ? "st"
      : newDate.getDate() % 10 === 2 && newDate.getDate() !== 12
      ? "nd"
      : newDate.getDate() % 10 === 3 && newDate.getDate() !== 13
      ? "rd"
      : "th";

  const formattedDateWithSuffix = formattedDate + suffix;

  return (
    <>
      <div className='onboarding-finale-message-container'>
        <FinaleMessage useCases={useCases} />
      </div>
      <div className='onboarding-finale-useage-container'>
        <div className='report'>
          <h4>SAMPLE: Daily report for {formattedDateWithSuffix}:</h4>
          <p className='analysis-intro'>
            Today, Blueprint has analysed {totalChangesCount} changes from:{" "}
          </p>
          <div className='app-counters'>
            {tools.slice(0, 6).map((tool, index) => (
              <div className='app-counter' key={index}>
                <div className='app-icon'>
                  <img src={`/assets/images/external-logos/${tool}.svg`} alt={tool} />
                </div>
                <div className='app-name'>
                  <h4>{tool}</h4>
                  <span className='stat'>
                    {" "}
                    <CountUp end={randomNumbers[index]} /> changes
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className='report-section'>
            <h5>Employee Benefits Administration</h5>
            <ul>
              <li>
                Enhanced the enrollment process for smoother benefits administration{" "}
                <span className='name'>Sophia W</span>
              </li>
              <li>
                Implemented intelligent eligibility checks to ensure compliance with
                regulations <span className='name'>Emily M</span>
              </li>
              <li>
                Streamlined benefits reporting and analytics for better insights into usage and
                costs <span className='name'>Michael B</span>
              </li>
            </ul>
            <h5>Payroll Management System</h5>
            <ul>
              <li>
                Updated to an advanced tax calculation algorithms to ensure accurate deductions{" "}
                <span className='name'>Ethan T</span>
              </li>
              <li>
                Upgraded to a real-time payroll processing engine for faster payroll cycles{" "}
                <span className='name'>Ava L</span>
              </li>
            </ul>
            <h5>Performance Management System</h5>
            <ul>
              <li>
                Developed a 360-degree feedback module for comprehensive performance evaluation{" "}
                <span className='name'>Mia R</span>
              </li>
              <li>
                Added goal-setting and tracking features to align individual and company
                objectives <span className='name'>Benjamin S</span>
              </li>
            </ul>

            <h5>Recruitment and Onboarding Automation</h5>
            <ul>
              <li>
                Automated resume screening and shortlisting process for efficient candidate
                evaluation <span className='name'>Olivia K</span>
              </li>
              <li>
                Added video interviewing capabilities to streamline the hiring process{" "}
                <span className='name'>Ethan T</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default OnboardingGetStarted;
