import IconContainer from "../icon.container";

function IconEmail(props) {
  return (
    <IconContainer size={props.size} classes={props.classes}>
      <path
        d='M7.49811 9.74902L10.4248 11.5534C11.3905 12.1488 12.6094 12.1488 13.5752 11.5534L16.5019 9.74902'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='2.99625'
        y='4.99707'
        width='18.0075'
        height='14.0058'
        rx='3'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </IconContainer>
  );
}

export default IconEmail;
