import React from "react";
import { Link } from "react-router-dom";
import Icon from "../../components/icon";

function LayoutOnboardingCopyright() {
  return (
    <footer className='copyright'>
      <span className='copyright'>&copy; Blueprint AI</span>
      <span className='support'>
        <Icon name='email' />
        <Link to='https://blueprint.ai/support' target='_blank'>
          Support
        </Link>
      </span>
    </footer>
  );
}

export default LayoutOnboardingCopyright;
