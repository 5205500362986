import IconContainer from "../icon.container";

function IconStartup(props) {
  return (
    <IconContainer size={props.size} classes={props.classes}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0978 12.1234L11.6988 16.4426C11.3008 16.761 10.7269 16.7292 10.3665 16.3688L7.63109 13.6334C7.27064 13.273 7.23886 12.6991 7.5573 12.3011L11.8765 6.90206C13.8515 4.43325 16.8417 2.99609 20.0034 2.99609V2.99609C20.5559 2.99609 21.0038 3.444 21.0038 3.99651V3.99651C21.0038 7.15813 19.5666 10.1483 17.0978 12.1234Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.0017 13.001V17.3848C16.0017 17.7637 15.7876 18.1101 15.4486 18.2796L13.0317 19.4881C12.7723 19.6178 12.4694 19.6285 12.2015 19.5176C11.9335 19.4066 11.7269 19.1848 11.6352 18.9097L10.9996 17.0026'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.99793 13.0001L5.09098 12.3645C4.81585 12.2728 4.59406 12.0662 4.48307 11.7982C4.37209 11.5303 4.38284 11.2274 4.51254 10.968L5.72104 8.55107C5.89049 8.21216 6.23687 7.99805 6.61577 7.99805H10.9996'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.74746 20.5909L2.99622 21.0042L3.40954 18.253C3.54362 17.3605 4.24424 16.6599 5.13675 16.5258V16.5258C5.7817 16.4289 6.43437 16.6438 6.89553 17.105C7.3567 17.5661 7.57163 18.2188 7.47472 18.8637V18.8637C7.34061 19.7562 6.63997 20.4568 5.74746 20.5909V20.5909Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </IconContainer>
  );
}

export default IconStartup;
