import IconContainer from "../icon.container";

function IconSupport(props) {
  return (
    <IconContainer size={props.size} classes={props.classes}>
      <path
        d='M19.56 7.11914C21.48 10.0891 21.479 13.9131 19.561 16.8821'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.5476 8.45236C17.5068 10.4116 17.5068 13.5882 15.5476 15.5475C13.5883 17.5067 10.4117 17.5067 8.45248 15.5475C6.49322 13.5882 6.49322 10.4116 8.45248 8.45236C10.4117 6.4931 13.5883 6.4931 15.5476 8.45236'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.321 7.55118L16.905 4.41218C17.281 3.95518 17.969 3.92218 18.388 4.34018L19.661 5.61318C20.08 6.03218 20.046 6.72018 19.589 7.09618L16.45 9.68018'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.67899 16.4488L7.09499 19.5878C6.71899 20.0448 6.03099 20.0778 5.61199 19.6598L4.33899 18.3868C3.91999 17.9678 3.95399 17.2798 4.41099 16.9038L7.54999 14.3198'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.55099 9.67911L4.41099 7.09511C3.95399 6.71911 3.92099 6.03111 4.33899 5.61211L5.61199 4.33911C6.03099 3.92011 6.71899 3.95411 7.09499 4.41111L9.67899 7.55011'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.449 14.3208L19.588 16.9048C20.045 17.2808 20.078 17.9688 19.66 18.3878L18.387 19.6608C17.968 20.0798 17.28 20.0458 16.904 19.5888L14.32 16.4498'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.882 19.5611C13.913 21.4791 10.088 21.4801 7.11899 19.5601'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.43899 7.11816C2.52099 10.0872 2.51999 13.9112 4.43999 16.8812'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.881 4.44024C13.911 2.52024 10.087 2.52124 7.11801 4.43924'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </IconContainer>
  );
}

export default IconSupport;
