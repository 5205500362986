import React from "react";

function Logo({ type = "logo" }) {
  return (
    <div className='logo-container'>
      <a href='https://blueprint.ai'>
       <img src={`/assets/images/${type === "icon" ? "logo-icon" : "logo"}.svg`} alt='Logo' />
      </a>
    </div>
  );
}

export default Logo;
