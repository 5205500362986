export const OutputCardData = (firstName, lastName, webAddress, companyName, email, jobTitle) => {
  return [
    {
      id: "1",
      title: `Bug Tracker: Front end`,
      description: `Fixed <strong>asdasdasd</strong>an error in social media stats calculation and updated overall score calculation`,
      jobTypes: ["Product", "Engineering", "Support", "Executive"],
      companyTypes: ["Startup", "Established Corp.", "Modern Tech Co."],
      tool: "Slack",
      problems: null,
    },
    {
      id: "2",
      title: `${companyName} Library updates and tweaks`,
      description: `Updated the select2 library to version 4.1.0-rc.O - joe@${webAddress}, `,
      jobTypes: ["Product", "Engineering", "Support", "Executive"],
      companyTypes: ["Startup", "Established Corp.", "Modern Tech Co."],
      tool: "Jira",
      problems: null,
    },
    {
      id: "3",
      title: `sean@${webAddress} updated: front-end project`,
      description: `Changed the visual design of the welcome page for a more appealing and readable look - joe@${webAddress}`,
      jobTypes: ["Product", "Engineering", "Support", "Executive"],
      companyTypes: ["Startup", "Established Corp.", "Modern Tech Co."],
      tool: "GitHub",
      problems: null,
    },
    {
      id: "4",
      title: `Project update: legal docs`,
      description: `Added links to terms of service and privacy policy pages on the RSVP form - frank@${webAddress}`,
      jobTypes: ["Product", "Engineering", "Support", "Executive"],
      companyTypes: ["Startup", "Established Corp.", "Modern Tech Co."],
      tool: "Notion",
      problems: null,
    },
    {
      id: "5",
      title: `Project: Front-end - enterprise`,
      description: `Added a footer to RSVP confirmation emails with anti-spam info, cancellation link, and service provider attribution - diego@${webAddress}`,
      jobTypes: ["Product", "Engineering", "Support", "Executive"],
      companyTypes: ["Startup", "Established Corp.", "Modern Tech Co."],
      tool: "Teams",
      problems: null,
    },
    {
      id: "6",
      title: `Backend Update - Consumer team`,
      description: `Added Airbrake error tracking to help developers identify and fix errors more efficiently, with updated configuration settings - jennifer@${webAddress}`,
      jobTypes: ["Product", "Engineering", "Support", "Executive"],
      companyTypes: ["Startup", "Established Corp.", "Modern Tech Co."],
      tool: "Asana",
      problems: null,
    },
    {
      id: "7",
      title: `App Modal Magic`,
      description: `The app modal now reloads the page when the user clicks the back button in their browser - diego@${webAddress}`,
      jobTypes: ["Product", "Engineering", "Support", "Executive"],
      companyTypes: ["Startup", "Established Corp.", "Modern Tech Co."],
      tool: "Confluence",
      problems: null,
    },
  ];
};
