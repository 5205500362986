import IconContainer from "../icon.container";

function IconProduct(props) {
  return (
    <IconContainer size={props.size} classes={props.classes}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 21H4C2.895 21 2 20.105 2 19V5C2 3.895 2.895 3 4 3H20C21.105 3 22 3.895 22 5V19C22 20.105 21.105 21 20 21Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2 8H22'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.77899 5.49012C4.77599 5.49012 4.77399 5.49212 4.77399 5.49512C4.77399 5.49812 4.77599 5.50012 4.77899 5.50012C4.78199 5.50012 4.78399 5.49812 4.78399 5.49512C4.78399 5.49212 4.78199 5.49012 4.77899 5.49012'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.32202 5.49012C7.31902 5.49012 7.31702 5.49212 7.31702 5.49512C7.31702 5.49812 7.32002 5.50012 7.32202 5.50012C7.32502 5.50012 7.32702 5.49812 7.32702 5.49512C7.32702 5.49212 7.32502 5.49012 7.32202 5.49012'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.86102 5.49012C9.85802 5.49012 9.85602 5.49212 9.85602 5.49512C9.85602 5.49812 9.85802 5.50012 9.86102 5.50012C9.86402 5.50012 9.86602 5.49812 9.86602 5.49512C9.86602 5.49212 9.86402 5.49012 9.86102 5.49012'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 17.4998V15.9138C8 15.6488 8.105 15.3938 8.293 15.2068L12.293 11.2068C12.684 10.8158 13.317 10.8158 13.707 11.2068L14.293 11.7928C14.684 12.1838 14.684 12.8168 14.293 13.2068L10.293 17.2068C10.105 17.3948 9.851 17.4998 9.586 17.4998H8H8Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 17.5H16'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </IconContainer>
  );
}

export default IconProduct;
