import React from "react";
import { Outlet } from "react-router-dom";

// components

import LayoutProgress from "./layout.progress";
import LayoutOnboardingContent from "./layout.onboarding.content";

function LayoutOnboarding() {
  return (
    <div className='blueprint-onboarding'>
      <LayoutProgress />
      <LayoutOnboardingContent>
        <Outlet />
      </LayoutOnboardingContent>
    </div>
  );
}

export default LayoutOnboarding;
