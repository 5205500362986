export const toolsData = [
  {
    id: 3,
    name: "GitHub",
    image: "GitHub.svg",
  },
  {
    id: 9,
    name: "Jira",
    image: "Jira.svg",
  },
  {
    id: 1,
    name: "Slack",
    autoFocus: true,
    image: "Slack.svg",
  },
  {
    id: 2,
    name: "Teams",
    image: "Teams.svg",
  },
  {
    id: 4,
    name: "Bitbucket",
    image: "Bitbucket.svg",
  },
  {
    id: 5,
    name: "Gitlab",
    image: "Gitlab.svg",
  },
  {
    id: 6,
    name: "Trello",
    image: "Trello.svg",
  },
  {
    id: 7,
    name: "Asana",
    image: "Asana.svg",
  },
  {
    id: 8,
    name: "Monday",
    image: "Monday.svg",
  },
  {
    id: 10,
    name: "Notion",
    image: "Notion.svg",
  },

  {
    id: 11,
    name: "Confluence",
    image: "Confluence.svg",
  },
];
