import { Navigate } from "react-router-dom";

export function navigateUser(user) {
  if (!user.firstName || !user.lastName || !user.companyName || !user.email) {
    return <Navigate to='/' />;
  } else if (!user.jobTitle && user.companyType) {
    return <Navigate to='/about-you' />;
  } else if (!user.problems || user.problems.length === 0) {
    return <Navigate to='/problems' />;
  } else if (!user.tools || user.tools.length === 0) {
    return <Navigate to='/tools' />;
  }
}
