import React from "react";

function LayoutOnboardingContent({ children }) {
  return (
    <div className='onboarding-content'>
      <div className='onboarding-wrapper'>{children}</div>
    </div>
  );
}

export default LayoutOnboardingContent;
